import { OktaAuthOptions } from '@okta/okta-auth-js'

export const scopeDomainPrefix = 'locatiedashboard-'
const redirectUri = `${window.location.origin}/callback`

const pipelineValues = {
  envValue: '#{OktaOmgeving}#',
  oktaAuthentication: '#{OktaAuthentication}#',
  apiHostValue: '#{ApiHost}#',
  clientIdValue: '#{OktaClientId}#',
  domainValue: '#{OktaDomain}#',
  issuerValue: '#{OktaIssuer}#',
}

const localValues: typeof pipelineValues = {
  oktaAuthentication: 'on',
  // envValue: 'dev',
  // apiHostValue: 'https://lmo-oo-api-api.sandbox.royalfloraholland.com',
  // clientIdValue: '0oa7ahwb97BzRrZvx0x7', // dev
  envValue: 'sys',
  apiHostValue: 'https://lmo-to-api-api.operstaging.royalfloraholland.com',
  clientIdValue: '0oa73744x0gbIt98l0x7', // sys
  domainValue: 'https://identity-test.royalfloraholland.com/oauth2/default',
  issuerValue: 'https://identity-test.royalfloraholland.com/oauth2/default',
}

type KeyType = keyof typeof pipelineValues

// The {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used.
Object.keys(pipelineValues).forEach(key => {
  if (pipelineValues[key as KeyType].startsWith('#')) {
    pipelineValues[key as KeyType] = localValues[key as KeyType]
  }
})

export type AppConfig = {
  env: 'dev' | 'sys' | 'acc' | 'prd' | string
  oktaRoute: string
  api: {
    host: string
    path: string
    version: string
  }
  oidc: OktaAuthOptions
}
export const Config: AppConfig = {
  env: pipelineValues.envValue,
  oktaRoute: pipelineValues.oktaAuthentication,
  api: {
    host: pipelineValues.apiHostValue,
    path: 'dashboard',
    version: 'v1',
  },
  oidc: {
    clientId: pipelineValues.clientIdValue,
    issuer: pipelineValues.issuerValue,
    redirectUri,
    scopes: [
      'openid',
      'profile',
      'email',
      `lmo-locatiedashboard-api-${pipelineValues.envValue}`,
    ],
    pkce: process.env.NODE_ENV === 'test' ? false : true,
    devMode: process.env.NODE_ENV === 'development' ? true : false,
  },
}
