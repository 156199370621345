import {
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
  isWithinInterval,
  formatISO,
  startOfDay,
  endOfDay,
} from 'date-fns'
import { nl } from 'date-fns/locale'
import { z } from 'zod'

import { useState } from 'react'

import { Typography, Stack, Grid, Box, Tabs, Tab } from '@mui/material'
import { createFileRoute } from '@tanstack/react-router'

import { DashboardCard } from '../components/dashboard/DashboardCard'
import { DashboardDataGrid } from '../components/dashboard/DashboardDataGrid'
import { SearchFormDashboard } from '../components/dashboard/SearchFormDashboard'
import content from '../content'
import { getSelectedLocation } from '../services/selectedLocation'
import { DashboardTypes } from '../types/Dashboard'
import { authenticatedFetch } from '../utils/authenticatedFetch'
import { ensureAuthenticated } from '../utils/ensureAuthenticated'
import { getFullWeek } from '../utils/getDates'
import { getLast30WorkingDays } from '../utils/getLast30WorkingDays'

export const Route = createFileRoute('/')({
  component: () => <Dashboard />,
  validateSearch: z.object({
    date: z.string().optional(),
    mode: z.enum(['day', 'week', 'lastThirtyDays']).optional(),
    location: z.string().optional(),
  }),
  loaderDeps: ({ search }) => ({
    date: search.date ?? format(new Date(), 'yyyy-MM-dd'),
    mode: search.mode ?? 'day',
    location: search.location ?? getSelectedLocation() ?? 'aalsmeer',
  }),
  beforeLoad: ensureAuthenticated,
  loader: ({ context, deps: { date, location, mode } }) =>
    context.queryClient.fetchQuery({
      queryKey: ['getDashboardDetails', date, location],
      queryFn: async () => {
        const { start, end } =
          mode === 'lastThirtyDays'
            ? getLast30WorkingDays(parseISO(date))
            : {
                start: startOfDay(startOfWeek(date, { weekStartsOn: 1 })),
                end: endOfDay(endOfWeek(date, { weekStartsOn: 1 })),
              }

        const response = await authenticatedFetch('/LocationDashboard', {
          oktaAuth: context.auth.oktaAuth,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            location,
            startDate: formatISO(start),
            endDate: formatISO(end),
            stroom: 'Klok',
            logistiekMiddel: 'Statiegeldlegbord',
          }),
        })

        return response.json().then(json => z.array(DashboardTypes).parse(json))
      },
      staleTime: 10,
    }),
})

const sum = (a: number, b: number) => a + b

export const calculateVerschil = ({
  afgeleverd,
  ingeleverdBijDepot,
  ingeslagen,
}: DashboardTypes) => afgeleverd + ingeleverdBijDepot - ingeslagen

const Dashboard = () => {
  const {
    title,
    informationFields,
    datagrid: { noData },
  } = content.dashboard
  const { date, mode } = Route.useLoaderDeps()

  const [activeTab, setActiveTab] = useState<number>(0)
  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const inputDate = parseISO(date)

  const { start, end } =
    mode === 'lastThirtyDays'
      ? getLast30WorkingDays(inputDate)
      : {
          start: startOfWeek(inputDate, { weekStartsOn: 1 }),
          end: endOfWeek(inputDate, { weekStartsOn: 1 }),
        }

  const data = Route.useLoaderData().filter(({ veildatum }) =>
    isWithinInterval(parseISO(veildatum), { start, end })
  )

  const dataInput = data
    .filter(
      ({ veildatum }) =>
        mode === 'lastThirtyDays' || mode === 'week' || veildatum === date
    )
    .map(({ ingeslagen }) => ingeslagen)
    .reduce(sum, 0)

  const dataDeliverd = data
    .filter(
      ({ veildatum }) =>
        mode === 'lastThirtyDays' || mode === 'week' || veildatum === date
    )
    .map(({ afgeleverd }) => afgeleverd)
    .reduce(sum, 0)

  const dataDepotDeliverd = data
    .filter(
      ({ veildatum }) =>
        mode === 'lastThirtyDays' || mode === 'week' || veildatum === date
    )
    .map(({ ingeleverdBijDepot }) => ingeleverdBijDepot)
    .reduce(sum, 0)

  const dataDifference = data
    .filter(
      ({ veildatum }) =>
        mode === 'lastThirtyDays' || mode === 'week' || veildatum === date
    )
    .map(calculateVerschil)
    .reduce(sum, 0)

  return (
    <>
      <SearchFormDashboard />
      <Stack direction='column'>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label={title}
          sx={{ mb: 4, mt: 6 }}
        >
          <Tab label={title} />
        </Tabs>
        <Stack direction='row' sx={{ mb: 4 }}>
          {mode === 'day' && (
            <Typography variant='h5'>
              {format(parseISO(date), 'dd MMMM yyyy', { locale: nl })}
            </Typography>
          )}
          {mode === 'week' && (
            <>
              <Typography variant='h5'>
                Week {format(parseISO(date), 'II')}
              </Typography>
              <Typography variant='h5' className='book' sx={{ pl: 1 }}>
                ({getFullWeek(date)})
              </Typography>
            </>
          )}
          {mode === 'lastThirtyDays' && (
            <>
              <Typography variant='h5'>
                {content.dashboard.cumulatives}
              </Typography>
              <Typography variant='h5' className='book' sx={{ pl: 1 }}>
                ({format(start, 'dd-MM-yyyy')} - {format(end, 'dd-MM-yyyy')})
              </Typography>
            </>
          )}
        </Stack>
        <Grid container spacing={3}>
          <DashboardCard title={informationFields.input} amount={dataInput} />
          <DashboardCard
            title={informationFields.delivered}
            amount={dataDeliverd}
          />
          <DashboardCard
            title={informationFields.deliveryDepot}
            amount={dataDepotDeliverd}
            titleExtra={informationFields.deliveryDepotByRFH}
          />
          <DashboardCard
            title={informationFields.difference}
            amount={dataDifference}
            explanation={informationFields.differenceExplained}
          />
        </Grid>

        <Box sx={{ mt: 3 }}>
          <DashboardDataGrid dashboardData={data} noData={noData} />
        </Box>
      </Stack>
    </>
  )
}

export default Dashboard
