import { Typography, Box, Grid, Paper } from '@mui/material'

type DashboardCardProps = {
  title: string
  titleExtra?: string
  amount: number | undefined
  explanation?: string
}

export const DashboardCard = ({
  title,
  titleExtra,
  amount,
  explanation,
}: DashboardCardProps) => (
  <Grid item xs={12} md={3}>
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 4,
        pb: 6,
        gap: 2,
        border: 'none',
        height: '100%',
      }}
    >
      {titleExtra ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h4' sx={{ fontWeight: 400 }}>
            {title}
          </Typography>
          <Typography variant='h6'>{titleExtra}</Typography>
        </Box>
      ) : (
        <Typography variant='h4' sx={{ fontWeight: 400 }}>
          {title}
        </Typography>
      )}
      <Box>
        <Typography
          variant='h1'
          component={'div'}
          sx={{
            fontWeight: 700,
            color:
              title === 'Verschil'
                ? amount && amount > 0
                  ? 'green'
                  : amount && amount < 0
                  ? 'red'
                  : 'inherit'
                : 'inherit',
          }}
        >
          {amount}
        </Typography>
        <Typography variant='h6'>{explanation}</Typography>
      </Box>
    </Paper>
  </Grid>
)
