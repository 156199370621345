const content = {
  appbar: ' Dashboard logistieke middelen',
  alerts: {
    error: 'Uw verzoek kon niet worden verwerkt.',
  },
  logout: 'Uitloggen',
  dashboard: {
    title: 'Dashboard',
    cumulatives: 'Cumulatieven afgelopen 30 werkdagen',
    buttons: {
      search: 'Zoeken',
      delete: 'Wissen',
    },
    filters: {
      dateSelection: 'Veildatum',
      day: 'Dag',
      week: 'Week',
      lastThirtyDays: '30 dagen',
      logistic: 'Logistieke middelen',
      stream: 'Stroom',
      location: 'Locatie',
    },
    informationFields: {
      input: 'Ingeslagen',
      delivered: 'Afgeleverd',
      deliveryDepot: 'Ingeleverd bij depot',
      deliveryDepotByRFH: 'door RFH',
      difference: 'Verschil',
      differenceExplained: 'Afgeleverd + Ingeleverd - Ingeslagen',
    },
    datagrid: {
      noData:
        'Geen data beschikbaar om een tabel te tonen, pas het filter aan.',
      amount: 'Aantal statiegeldlegborden',
      difference: 'Verschil',
    },
  },
}

export default content
