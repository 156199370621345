import React from 'react'
import { createRoot } from 'react-dom/client'

import { ThemeProvider, createTheme, CssBaseline } from '@mui/material'
import { nlNL } from '@mui/x-data-grid-premium'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Security, useOktaAuth } from '@okta/okta-react'
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext'
import { ArrowDownIcon, ArrowUpIcon } from '@rfh-core/icons'
import rfhTheme from '@rfh-core/theme'
import { setMuiLicenseKey } from '@rfh-core/theme'
import '@rfh-core/theme/dist/fonts.css'
import * as Sentry from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider, createRouter } from '@tanstack/react-router'

import packageJson from '../package.json'
import { Config } from './Config'
import { routeTree } from './routeTree.gen'
import { queryClient } from './utils/queryClient'

const oktaConfig = new OktaAuth({
  clientId: Config.oidc.clientId,
  issuer: Config.oidc.issuer,
  redirectUri: window.location.origin + '/callback',
  scopes: Config.oidc.scopes,
  pkce: Config.oidc.pkce,
})

Sentry.init({
  dsn: 'https://1ebe8f75440b5058740c3bf385c6d3a0@o902103.ingest.us.sentry.io/4506903581032448',
  integrations: [
    Sentry.browserTracingIntegration(),
    // Sentry.replayIntegration({
    //   maskAllText: false,
    //   blockAllMedia: false,
    // }),
  ],
  enabled: process.env.NODE_ENV === 'production',
  release: `${packageJson.name}@${packageJson.version}`,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root')
// As documented here: https://react.dev/blog/2022/03/08/react-18-upgrade-guide
const router = createRouter({
  routeTree,
  context: {
    queryClient,
    // This will be set after we wrap the app in an AuthProvider
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    auth: undefined!,
  },
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  // Recommended to set this to 0 in the React Router docs
  defaultPreloadStaleTime: 0,
})

setMuiLicenseKey()

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Security oktaAuth={oktaConfig} restoreOriginalUri={restoreOriginalUri}>
        <InnerApp />
      </Security>
    </ThemeProvider>
  </QueryClientProvider>
)

if (container) {
  const root = createRoot(container)
  if (
    process.env.NODE_ENV === 'development' &&
    import.meta.env.VITE_USE_MIRAGE === 'true'
  ) {
    const loadServer = async () => {
      const { makeServer } = await import('./mirage/server')
      makeServer({ environment: 'development' })
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      )
    }
    loadServer()
  } else {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    )
  }
}

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export const theme = createTheme(rfhTheme, {
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          marginTop: '12px !important',
          borderBottom: `2px solid ${rfhTheme.rfhColors.grey[400]}`,
          overflow: 'visible',
        },
        scroller: {
          overflow: 'visible !important',
        },
        indicator: {
          background: rfhTheme.rfhColors.dutchOrange,
          bottom: '-2px',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          color: `${rfhTheme.rfhColors.black} !important`,
          fontWeight: 'bold',
          '&.Mui-selected': {
            color: `${rfhTheme.rfhColors.dutchOrange} !important`,
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        disableColumnMenu: true,
        columnHeaderHeight: 54,
        slots: {
          detailPanelExpandIcon: ArrowDownIcon,
          detailPanelCollapseIcon: ArrowUpIcon,
          columnSortedAscendingIcon: ArrowUpIcon,
          columnSortedDescendingIcon: ArrowDownIcon,
          columnMenuSortAscendingIcon: ArrowUpIcon,
          columnMenuSortDescendingIcon: ArrowDownIcon,
        },
        slotProps: {
          pagination: {
            labelRowsPerPage: 'Geen resultaat',
          },
        },
        autoHeight: true,
        disableRowSelectionOnClick: true,
        localeText: {
          ...nlNL.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: 'Geen resultaten',
          noResultsOverlayLabel: 'Geen resultaten',
        },
        pageSizeOptions: [20, 50, 100],
      },
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row:nth-of-type(even)': {
            background: rfhTheme.rfhColors.white,
          },
          '& .MuiDataGrid-row:hover': {
            background: rfhTheme.rfhColors.grey[50],
          },
          '& .MuiDataGrid-footerContainer': {
            background: rfhTheme.rfhColors.white,
          },
          border: 'none !important',
        },
        sortIcon: {
          color: rfhTheme.rfhColors.black,
        },
        row: {
          '&:hover': {
            backgroundColor: `${rfhTheme.rfhColors.grey[50]} !important`, // needed to override color from rfhtheme
          },
          '&--detailPanelExpanded': {
            backgroundColor: `${rfhTheme.rfhColors.grey[50]} !important`, // needed to override color from rfhtheme
          },
        },
        columnHeaderTitle: {
          color: 'black',
          fontWeight: 700,
        },
        aggregationColumnHeaderLabel: {
          display: 'none',
        },
        columnHeader: {
          backgroundColor: rfhTheme.rfhColors.white,
        },
        columnHeaders: {
          background: rfhTheme.rfhColors.white,
        },
        footerCell: {
          color: rfhTheme.rfhColors.black,
        },
        'pinnedRows--bottom': {
          zIndex: 1,
          boxShadow: 'none',

          '& .MuiDataGrid-footerCell': {
            color: rfhTheme.rfhColors.black,
          },
        },
      },
    },
  },
})

function InnerApp() {
  const auth = useOktaAuth()
  return <RouterProvider router={router} context={{ auth, queryClient }} />
}

const restoreOriginalUri: RestoreOriginalUriFunction = async (
  oktaAuth,
  originalUri
) => {
  router.update({
    context: {
      queryClient,
      auth: {
        oktaAuth,
        authState: oktaAuth.authStateManager.getAuthState(),
      },
    },
  })
  await router.navigate({
    to: toRelativeUrl(originalUri || '/', window.location.origin),
  })
}
