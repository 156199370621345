/* eslint-disable @typescript-eslint/no-redeclare */
import { format } from 'date-fns'
import { z } from 'zod'

export const DashboardTypes = z.object({
  frontendOnlyId: z.string(),
  veildatum: z.string().transform(date => format(new Date(date), 'yyyy-MM-dd')),
  location: z.string(),
  stroom: z.string(),
  logistiekMiddel: z.string(),
  ingeslagen: z.number(),
  afgeleverd: z.number(),
  ingeleverdBijDepot: z.number(),
})
export type DashboardTypes = z.infer<typeof DashboardTypes>

export type FilteredData = {
  ingeslagen: number
  afgeleverd: number
  ingeleverdBijDepot: number
  calculatedTotals: number
}
