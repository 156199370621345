import {
  DataGridPremium,
  DataGridPremiumProps,
  nlNL,
} from '@mui/x-data-grid-premium'

type OflmDataGridProps = {
  noRowsLabel: string
}

export const OflmDataGrid = ({
  noRowsLabel,
  ...dataGridProps
}: OflmDataGridProps & DataGridPremiumProps) => (
  <DataGridPremium
    disableColumnMenu
    disableColumnReorder
    disableColumnResize
    pageSizeOptions={[20, 50, 100]}
    slotProps={{
      pagination: {
        labelRowsPerPage: 'Resultaten per pagina',
      },
    }}
    getDetailPanelHeight={() => 200}
    columnHeaderHeight={40}
    sx={{
      overflowX: 'scroll',
      fontWeight: 100,
      fontSize: 'small',
      '& .MuiIconButton-root': {
        fontSize: '18px',
      },
      '& .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-row:nth-of-type(odd)': {
        background: ({ rfhColors }) => rfhColors.white,
      },
      '& .MuiDataGrid-row:hover': {
        background: ({ rfhColors }) => rfhColors.grey[50],
      },
    }}
    autoHeight
    disableRowSelectionOnClick
    pagination
    localeText={{
      ...nlNL.components.MuiDataGrid.defaultProps.localeText,
      noRowsLabel,
      noResultsOverlayLabel: noRowsLabel,
    }}
    initialState={{
      pagination: {
        paginationModel: { pageSize: 20, page: 0 },
      },
    }}
    {...dataGridProps}
  />
)
