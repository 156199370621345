import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale/nl'
import * as yup from 'yup'

import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useLoaderDeps, useRouter } from '@tanstack/react-router'

import content from '../../content'
import { setSelectedLocation } from '../../services/selectedLocation'
import { FilterDrawer } from '../FilterDrawer'

const searchSchema = yup.object({
  date: yup.string().required(),
  mode: yup.string().oneOf(['day', 'week', 'lastThirtyDays']).required(),
  location: yup.string().required(),
})

export const SearchFormDashboard = () => {
  const { filters, buttons } = content.dashboard
  const { location, date, mode } = useLoaderDeps({ from: '/' })
  // useMemo is used to prevent the defaultValues from being recalculated on every render
  // this would trigger an infinite loop, because useFormUrlState calls form.reset on every query change
  const defaultValues: yup.InferType<typeof searchSchema> = useMemo(
    () => ({
      date,
      location,
      mode,
    }),
    []
  )

  const { handleSubmit, control, formState, register, reset } = useForm({
    defaultValues,
    resolver: yupResolver(searchSchema),
  })

  const { navigate } = useRouter()

  return (
    <FilterDrawer>
      <form
        onSubmit={handleSubmit(search => {
          navigate({
            to: '/',
            search,
          })
        })}
      >
        <Box>
          <Typography variant='h3'>Filters</Typography>
        </Box>

        <FormControl>
          <InputLabel>{filters.dateSelection}</InputLabel>
          <Controller
            control={control}
            name='mode'
            render={({ field }) => (
              <RadioGroup
                aria-labelledby='demo-radio-buttons-group-label'
                row
                value={field.value}
                onChange={event => field.onChange(event.target.value)}
                name='radio-buttons-group'
              >
                <FormControlLabel
                  value='day'
                  control={<Radio />}
                  label={filters.day}
                />
                <FormControlLabel
                  value='week'
                  control={<Radio />}
                  label={filters.week}
                />
                <FormControlLabel
                  value='lastThirtyDays'
                  control={<Radio />}
                  label={filters.lastThirtyDays}
                />
              </RadioGroup>
            )}
          />
        </FormControl>

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
          <FormControl fullWidth>
            <Controller
              control={control}
              name='date'
              render={({ field }) => (
                <Paper>
                  <DateCalendar
                    value={parseISO(field.value)}
                    onChange={value => {
                      field.onChange(format(value, 'yyyy-MM-dd'))
                    }}
                    showDaysOutsideCurrentMonth
                    displayWeekNumber
                  />
                </Paper>
              )}
            />
          </FormControl>
        </LocalizationProvider>
        <TextField
          disabled
          fullWidth
          label={filters.logistic}
          value={'Statiegeldlegbord'}
        />
        <TextField disabled fullWidth label={filters.stream} value={'Klok'} />
        <FormControl fullWidth>
          <InputLabel htmlFor='Location'>{filters.location}</InputLabel>
          <NativeSelect
            inputProps={{
              ...register('location'),

              onChange: event => {
                setSelectedLocation(event.target.value)
              },
            }}
            id='Location'
            name='Location'
          >
            <option value={'aalsmeer'}>Aalsmeer</option>
            <option value={'naaldwijk'}>Naaldwijk</option>
            <option value={'rijnsburg'}>Rijnsburg</option>
            <option value={'eelde'}>Eelde</option>
          </NativeSelect>
        </FormControl>
        <Button type='submit' disabled={!formState.isValid} variant='contained'>
          {buttons.search}
        </Button>
        <Button variant='outlined' onClick={() => reset()}>
          {buttons.delete}
        </Button>
      </form>
    </FilterDrawer>
  )
}
