import { isWeekend, subDays, startOfDay, endOfDay } from 'date-fns'

export const getLast30WorkingDays = (inputDate: Date) => {
  let workingDaysCount = 0
  let currentDate = inputDate
  let startDate = inputDate

  while (workingDaysCount < 30) {
    if (!isWeekend(currentDate)) {
      startDate = currentDate
      workingDaysCount++
    }
    currentDate = subDays(currentDate, 1)
  }

  return {
    start: startOfDay(startDate),
    end: endOfDay(inputDate),
  }
}
