import { format } from 'date-fns'

import { useState } from 'react'

import { Box, Typography } from '@mui/material'
import { GridColDef, GridRowId } from '@mui/x-data-grid-premium'

import { DashboardTypes } from '../../types/Dashboard'
import { OflmDataGrid } from '../datagrid/OflmDataGrid'

type DashboardDataGridProps = {
  dashboardData: DashboardTypes[]
  noData: string
}

export const DashboardDataGrid = ({
  dashboardData,
  noData,
}: DashboardDataGridProps) => {
  const [pagination, setPagination] = useState({ pageSize: 20, page: 0 })

  const getUniqueId = (dashboardRow: DashboardTypes): GridRowId =>
    `row-${dashboardRow.frontendOnlyId}`

  const columns: GridColDef[] = [
    {
      field: 'veildatum',
      headerName: 'Datum',
      flex: 1,
      renderCell: params => {
        const { row } = params

        const { veildatum } = row

        return (
          <Box>
            <Typography
              variant='body2'
              component={'span'}
              sx={{ fontSize: '13px !important' }}
            >
              {format(veildatum, 'dd-MM-yyyy')}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: 'ingeslagen',
      headerName: 'Ingeslagen',
      flex: 1,
    },
    {
      field: 'afgeleverd',
      headerName: 'Afgeleverd',
      flex: 1,
    },
    {
      field: 'ingeleverdBijDepot',
      headerName: 'Ingeleverd bij depot',
      flex: 1,
    },
    {
      field: 'verschil',
      headerName: 'Verschil',
      flex: 1,
      sortable: false,
      renderCell: params => {
        const { row } = params
        const { afgeleverd, ingeleverdBijDepot, ingeslagen } = row

        const difference = afgeleverd + ingeleverdBijDepot - ingeslagen

        return (
          <Box
            sx={{
              fontWeight: 700,
              color:
                difference > 0 ? 'green' : difference < 0 ? 'red' : 'black',
            }}
          >
            {difference}
          </Box>
        )
      },
    },
  ]

  return (
    <OflmDataGrid
      disableVirtualization={process.env.NODE_ENV === 'test'}
      rows={dashboardData || []}
      columns={columns}
      noRowsLabel={noData}
      getRowId={getUniqueId}
      paginationModel={pagination}
      onPaginationModelChange={setPagination}
      initialState={{
        sorting: {
          sortModel: [{ field: 'veildatum', sort: 'asc' }],
        },
      }}
    />
  )
}
